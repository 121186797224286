import ApiService from "@/common/api/api.service";

const PagamentoService = {
    consultarPagamentoGSP: async function (id) {
        return await ApiService.query(`pagamento/obter-status-pagamento-pagseguro?id=${id}`)
    },
    atualizarPagamentoGSP: function (form) {
        return ApiService.post("pagamento/obter-status-pagamento-gsp", form);
    },
}

export default PagamentoService;
