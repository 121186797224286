<template>
  <b-card no-body class="mb-4 p-3">
    <div>
      <h4 class="card-title">{{ tabela.quantidadeItens }} registros</h4>
    </div>
    <div
      class="filtro-valorem d-flex align-items-center justify-content-between mb-4"
    >
      <div class="row w-100">
        <input-text
          class="col-3"
          :label="$t('PLACEHOLDER.FILTRAR_TRANSACAO')"
          v-model="filtro.termo"
          @blur="filtrar"
        />
        <input-autocomplete
          :label="$t('PLACEHOLDER.ESTABELECIMENTO')"
          v-model="filtro.estabelecimentoId"
          class="flex-fill col-3 autocomplete-transacao"
          :options="opcoes.estabelecimentos"
          @selected="filtrar"
        />
        <input-date
          class="col-2 input-label"
          ref="dataInicio"
          :label="$t('PLACEHOLDER.DATA_INICIO')"
          v-model="filtro.dataInicio"
          @blur="filtrar"
        />
        <input-date
          class="col-2 input-label"
          ref="dataFim"
          :label="$t('PLACEHOLDER.DATA_FIM')"
          v-model="filtro.dataFim"
          @blur="filtrar"
        />
        <b-form-group :label="$t('PLACEHOLDER.CONFIRMADA')" class="col-2">
          <input-checkbox
            ref="efetivadas"
            v-model="filtro.efetivadas"
            @onChange="filtrar"
          />
        </b-form-group>
        <input-select
          class="col-2"
          :options="opcoes.tipo"
          :label="$t('PLACEHOLDER.TIPO')"
          ref="tipo"
          v-model="filtro.tipo"
          @blur="filtrar"
        />
        <input-select
          class="col-2"
          :options="opcoes.status"
          :label="$t('GERAL.STATUS')"
          ref="status"
          v-model="filtro.status"
          @blur="filtrar"
        />
        <input-select
          class="col-2"
          :options="opcoes.plataforma"
          :label="$t('PLACEHOLDER.PLATAFORMA')"
          ref="plataforma"
          v-model="filtro.plataforma"
          @blur="filtrar"
        />
        <div class="extrato-transacao d-flex">
          <b-button class="ml-2" variant="outline-secondary" @click="limpar">
            <i class="fas fa-filter"></i>
            <i class="fas fa-times sub-i"></i>
          </b-button>
          <b-button
            class="ml-2"
            variant="outline-valorem-ciano"
            @click="filtrar"
          >
            <div class="d-flex align-items-center">
              <i class="fas fa-search mr-2"></i>
              <span>Filtrar</span>
            </div>
          </b-button>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center mb-2">
      <b-button
        @click="consultar()"
        v-if="
          dadosSelecionados.length == 1 &&
          (this.dadosSelecionados[0].statusPagamento ===
            'AUTHORIZATION_IN_PROGRESS' ||
            this.dadosSelecionados[0].statusPagamento === 'AUTHORIZED' ||
            this.dadosSelecionados[0].statusPagamento === 'UNKNOWN' ||
            this.dadosSelecionados[0].statusPagamento === 'CONFIRMED' ||
            this.dadosSelecionados[0].statusPagamento ===
              'CONFIRMATION_IN_PROGRESS' ||
            this.dadosSelecionados[0].statusPagamento === 'NEW')
        "
        variant="outline-primary"
        class="mr-2"
      >
        {{ `${$t("PAGAMENTO.CONSULTAR")}` }}
      </b-button>
    </div>

    <tabela
      v-model="tabela.dados"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      ref="tabela"
      backend-paginado
      sem-selecionar-todos
      @paginar="listar"
      class="tabela-transacoes"
    >
      <template #cell(pagamentoId)="dados">
        <span
          :class="
            dados.item.statusWebHook == 'Cancelado'
              ? 'texto-tachado'
              : 'texto-normal'
          "
        >
          {{ dados.value }}
        </span>
      </template>
      <template #cell(estabelecimentoNome)="dados">
        <span> {{ dados.value }} ({{ dados.item.estabelecimentoCnpj }}) </span>
      </template>
      <template #cell(nsuHost)="dados">
        <div
          :class="
            dados.item.adquirente == '5'
              ? ' ad-rede'
              : dados.item.adquirente == '18'
              ? ' ad-pagseguro'
              : ' '
          "
        >
          {{ dados.value }}
        </div>
      </template>
      <template #cell(detalhes)="dados">
        <b-button
          variant="primary"
          size="sm"
          @click="abrirDetalhes(dados.item.detalhes)"
          v-if="mostrarDetalhes(dados.item.status)"
          >{{ $t("GERAL.DETALHES") }}</b-button
        >
      </template>
      <template #cell(quantidadeCartoes)="dados">
        <span>
          {{ retornarNrCartoes(dados.value).text }}
        </span>
      </template>
      <template #cell(adquirente)="dados">
        <div v-if="dados.value">
          <img
            :src="adquirenteImg(dados.value).text"
            class="dimension-adquirente"
            :title="adquirenteTxt(dados.value).text"
          />
        </div>
      </template>
    </tabela>
    <modal-detalhes :modalData="detalhes" ref="modalDetalhes" />
  </b-card>
</template>

<script>
import CheckoutService from "@/common/services/checkout/checkout.service";
import EstabelecimentoService from "@/common/services/estabelecimentos/estabelecimentos.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { AlertasMixin } from "@/common/mixins/AlertasMixin";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  InputText,
  InputDate,
  InputAutocomplete,
  InputCheckbox,
  InputSelect,
} from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import Tabela from "@/components/tabelas/Tabela.vue";
import ModalDetalhes from "./components/ModalDetalhes";
import PagamentoService from "@/common/services/pagamento/pagamento.service";
import cloneDeep from "lodash.clonedeep";

export default {
  mixins: [AlertasMixin],
  name: "Index",
  components: {
    InputText,
    InputDate,
    InputAutocomplete,
    InputCheckbox,
    InputSelect,
    Tabela,
    ModalDetalhes,
  },
  data() {
    return {
      filtro: {
        termo: null,
        dataInicio: this.setInitialDate(),
        dataFim: null,
        estabelecimentoId: null,
        efetivadas: true,
        tipo: null,
        status: null,
        adquirente: null,
        plataforma: null,
      },
      botaoAtivarInativar: null,
      detalhes: null,
      opcoes: {
        estabelecimentos: [],
        tipo: helpers.TipoPagamento,
        status: helpers.StatusWebHook,
        adquirente: helpers.AdquirenteFiltro,
        plataforma: helpers.PlataformaFiltro,
      },
      tabela: {
        dados: [],
        colunas: [
          { key: "seletor", label: "" },
          {
            key: "pagamentoId",
            label: this.$t("TRANSACOES.ID"),
            thStyle: { width: "20%" },
          },
          {
            key: "estabelecimentoNome",
            label: this.$t("TRANSACOES.NOME_ESTABELECIMENTO"),
          },
          {
            key: "valor",
            label: this.$t("TRANSACOES.VALOR"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "valorOriginal",
            label: this.$t("TRANSACOES.VALOR_ORIGINAL"),
            formatter: (v) => {
              if (v) return helpers.formatarValor(v);
            },
          },
          {
            key: "dataCriacao",
            label: this.$t("GERAL.DT_CADASTRO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          {
            key: "dataPagamento",
            label: this.$t("TRANSACOES.DATA_PAGAMENTO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          // {
          //   key: "statusRequisicao",
          //   label: this.$t("TRANSACOES.STATUS_REQUISICAO_PAGAMENTO"),
          // },
          {
            key: "nsuHost",
            label: this.$t("TRANSACOES.NSU_HOST"),
          },
          {
            key: "numeroCartao",
            label: this.$t("TRANSACOES.NUMERO_CARTAO"),
          },
          {
            key: "bandeira",
            label: this.$t("TRANSACOES.BANDEIRA"),
          },
          {
            key: "numeroParcelas",
            label: this.$t("TRANSACOES.NUMERO_PARCELAS"),
          },
          {
            key: "statusPagamento",
            label: this.$t("TRANSACOES.STATUS_PAGAMENTO"),
          },
          {
            key: "statusWebHook",
            label: this.$t("TRANSACOES.STATUSWEBHOOK"),
          },
          {
            key: "tipoTransacao",
            label: this.$t("TRANSACOES.TIPO_TRANSACAO"),
          },
          {
            key: "codigoAutorizacao",
            label: this.$t("TRANSACOES.CODIGO_AUTORIZACAO"),
          },
          {
            key: "idExterno",
            label: this.$t("TRANSACOES.ID_EXTERNO"),
          },
          {
            key: "tipoPagamentoDescricao",
            label: this.$t("TRANSACOES.TIPO_PAGAMENTO"),
          },
          {
            key: "pagamentoMensagem",
            label: this.$t("TRANSACOES.MENSAGEM_PAGAMENTO"),
          },
          {
            key: "quantidadeCartoes",
            label: this.$t("TRANSACOES.QUANTIDADE_CARTOES"),
          },
          {
            key: "realizarAntecipacao",
            label: this.$t("TRANSACOES.REALIZAR_ANTECIPACAO"),
            formatter: (v) => {
              if (v) return "Sim";
              return "Não";
            },
          },
          {
            key: "dataLiquidacao",
            label: this.$t("TRANSACOES.DATA_LIQUIDACAO"),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, true);
            },
          },
          {
            key: "adquirente",
            label: this.$t("TRANSACOES.ADQUIRENTE"),
          },
          {
            key: "plataforma",
            label: this.$t("TRANSACOES.PLATAFORMA"),
          },
          // {
          //   key: "dataPagamentoTransacao",
          //   label: this.$t("TRANSACOES.DESTACAR_DATA"),
          //   formatter: (v) => {
          //     if (v) return "Sim";
          //     return "";
          //   },
          // },
        ],
        quantidadeItens: 0,
        paginaAtual: 0,
        porPagina: 0,
      },
      form: {
        id: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.TRANSACOES") },
    ]);
    this.buscarEstabelecimentos();
    // this.listar();
  },
  methods: {
    filtrar: function () {
      // this.$refs.tabela.limpar();
      this.listar();
    },
    buscarEstabelecimentos: function () {
      this.opcoes.estabelecimentos = [
        { value: null, text: "Todos estabelecimentos" },
      ];
      EstabelecimentoService.buscarEstabelecimentosDropdown().then((res) => {
        this.opcoes.estabelecimentos.push(
          ...res.data.data.estabelecimentos.map((el) => {
            return {
              value: el.id,
              text: el.nome,
            };
          })
        );
      });
    },
    listar: function (paginaAtual = 0, porPagina = 10) {
      this.$store.dispatch(START_LOADING);
      // console.log(this.filtro);
      CheckoutService.listar(this.filtro, paginaAtual, porPagina)
        .then((res) => {
          this.tabela.dados = res.data.data.itens;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
          this.tabela.quantidadeItens =
            res.data.data.paginacao.totalDeElementos;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirDetalhes(detalhes) {
      this.detalhes = detalhes;
      this.$refs.modalDetalhes.abrirModal();
    },
    mostrarDetalhes(status) {
      return status != "Pendente";
    },
    retornarNrCartoes: function (rav) {
      return helpers.NrCartoes.find((el) => el.value == rav);
    },
    limpar: function () {
      this.filtro = {
        termo: null,
        dataInicio: this.setInitialDate(),
        dataFim: null,
        estabelecimentoId: null,
        efetivadas: true,
        tipo: null,
        status: null,
        adquirente: null,
      };
      this.filtrar();
    },
    consultar: function () {
      this.confirmar(
        this.$t(`PAGAMENTO.TITULO_${"ATIVAR"}`),
        this.$t(`PAGAMENTO.CONFIRMACAO_CONSULTAR`),
        this.$t(`PAGAMENTO.BOTAO_CONSULTAR`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;
        this.$store.dispatch(START_LOADING);
        PagamentoService.consultarPagamentoGSP(
          this.dadosSelecionados[0].pagamentoId
        )
          .then(() => {
            this.toastSucesso(this.$t(`PAGAMENTO.ATUALIZAR_SUCESSO`));
            this.listar(this.paginaAtual, this.porPagina);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    salvar: function () {
      this.confirmar(
        this.$t(`PAGAMENTO.TITULO_${"ATIVAR"}`),
        this.$t(`PAGAMENTO.CONFIRMACAO_CONSULTAR`),
        this.$t(`PAGAMENTO.BOTAO_CONSULTAR`),
        this.$t(`GERAL.CANCELAR`)
      ).then((confirmado) => {
        if (!confirmado) return;

        //if (!this.validarAbas(false)) return false;

        let form = cloneDeep(this.form);
        form.id = this.dadosSelecionados[0].pagamentoId;

        this.$store.dispatch(START_LOADING);
        PagamentoService.atualizarPagamentoGSP(form)
          .then(() => {
            this.alertaSucesso(this.$t("PAGAMENTO.ATUALIZAR_SUCESSO")).then(
              () => {
                this.listar(this.paginaAtual, this.porPagina);
              }
            );
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      });
    },
    adquirenteImg: function (id) {
      return helpers.AdquirenteImagem.find((el) => el.value == id);
    },
    adquirenteTxt: function (id) {
      return helpers.AdquirenteCodigo.find((el) => el.value == id);
    },
    setInitialDate: function () {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      //console.log(formattedDate);
      return formattedDate;
    },
  },
  computed: {
    dadosSelecionados: function () {
      return this.tabela.dados.filter((el) => {
        return el.selecionado;
      });
    },
    permissao: function () {
      return helpers.validarAcesso("Estabelecimento");
    },
  },
};
</script>

<style lang="scss">
.autocomplete-transacao .dropdown-content {
  margin-top: 0px !important;
}

.tabela-transacoes td {
  font-size: 14px;
  padding: 5px !important;
  white-space: nowrap;
}

.tabela-transacoes th {
  white-space: nowrap;
  font-size: 14px;
}
.extrato-transacao {
  padding-top: 25px;
}
.extrato-transacao .btn {
  height: 35px;
}
.texto-tachado {
  text-decoration: line-through;
}
.texto-normal {
  text-decoration: none;
}
.dimension-adquirente {
  max-height: 18px;
}
.ad-rede {
  color: #fa8e00;
  font-weight: bold;
}
.ad-pagseguro {
  color: #15bc15;
  font-weight: bold;
}
</style>
